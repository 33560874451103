import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { GeneralForm } from "@components/Form"
import { Hero } from "@components/Hero"
import { Layout } from "@components/Layout"
import { Lottie } from "@components/Lottie"
import { Seo } from "@components/Seo"
import { Arrow } from "@components/UI"
import { usePageSetup } from "@components/usePageSetup"
import handshakeLottie from "@lottie/handshake_b.json"
import hatLottie from "@lottie/hat_b.json"
import { splitText } from "@utils"
import { graphql } from "gatsby"
import React from "react"

const ContactPage = ({ data }) => {
  const { themeColor } = usePageSetup({ themeColor: "bg-white-dark", displayGeneralForm: false })
  const { inOfficeImage, studioImage, roofJKImage, lakeFarmhouseImage, drinksImage } = data

  return (
    <Layout>
      <Seo
        title="Contact"
        description="Connect with JK - and let’s make an impact, together. Tell us how we can help you."
      />
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1
              data-aos="stagger"
              className="text-headline"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-gray-darkest": "How can we help you?",
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>
      <main>
        <section className="pb-clamp-30-36">
          <div className="container">
            <div className="mx-auto xl:w-10/12">
              <GeneralForm />
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className="pt-clamp-33-54 pb-clamp-46-66">
          <div className="container flex justify-center">
            <div className="grid xl:w-10/12 lg:grid-cols-10 gap-x-4 gap-y-20">
              <div data-aos="fade-up" className="lg:col-span-5">
                <div className="flex flex-col h-full lg:max-w-87">
                  <div className="flex justify-center w-full mt-auto lg:justify-start">
                    <div>
                      <Lottie animation={handshakeLottie} />
                    </div>
                  </div>
                  <div className="text-center mt-clamp-9-12 lg:text-left">
                    <h2 className="font-medium font-book text-clamp-18-22">Work with JK</h2>
                    <a
                      className="block mt-2 font-bold break-words transition-all duration-300 text-clamp-26-32 sm:hover:text-teal"
                      href="mailto:hello@jkdesign.com"
                      style={{ hyphens: "auto" }}
                    >
                      hello@jkdesign.com
                    </a>
                  </div>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="100" className="flex lg:col-span-5 lg:pl-16">
                <div className="flex flex-col h-full lg:max-w-96">
                  <div className="flex justify-center w-full mt-auto lg:justify-start">
                    <div>
                      <Lottie animation={hatLottie} width="135px" />
                    </div>
                  </div>
                  <div className="text-center mt-clamp-9-12 lg:text-left">
                    <h2 className="font-medium font-book text-clamp-18-22">Work at JK</h2>
                    <a
                      className="block mt-2 font-bold break-words transition-all duration-300 text-clamp-26-32 sm:hover:text-teal"
                      href="mailto:careers@jkdesign.com"
                      style={{ hyphens: "auto" }}
                    >
                      careers@jkdesign.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-transparent" fillColor="text-transparent" />
        <section className="relative overflow-hidden bg-teal-light">
          <div className="container absolute inset-x-0 top-0">
            <div className="aspect-w-1436 aspect-h-165 before:bg-white-dark after:bg-white-dark extend"></div>
          </div>
          <div className="container relative justify-center lg:flex">
            <div className="w-full lg:w-10/12">
              <Carousel>
                <CursorMarker>
                  <CursorMarker.Gallery className="bg-pink after:text-white" />
                </CursorMarker>
                <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                  <Carousel.Slide style={{ width: "auto" }}>
                    <div
                      data-aos="fade-up"
                      className="pointer-events-none w-75 h-98 sm:w-110 sm:h-78 lg:w-238 lg:h-150"
                    >
                      <GatsbyImage className="h-full" image={getImage(roofJKImage)} alt="JK Office." />
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide style={{ width: "auto" }}>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="200"
                      className="pointer-events-none w-75 h-98 sm:w-62 sm:h-78 lg:w-117 lg:h-150"
                    >
                      <GatsbyImage className="h-full" image={getImage(inOfficeImage)} alt="JK Office." />
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide style={{ width: "auto" }}>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="300"
                      className="pointer-events-none w-75 h-98 sm:w-62 sm:h-78 lg:h-150 lg:w-117"
                    >
                      <GatsbyImage className="h-full" image={getImage(studioImage)} alt="Studio." />
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide style={{ width: "auto" }}>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="400"
                      className="pointer-events-none w-75 h-98 sm:w-110 sm:h-78 lg:w-238 lg:h-150"
                    >
                      <GatsbyImage className="h-full" image={getImage(lakeFarmhouseImage)} alt="JK Office." />
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide style={{ width: "auto" }}>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="500"
                      className="pointer-events-none w-75 h-98 sm:w-62 sm:h-78 lg:h-150 lg:w-117"
                    >
                      <GatsbyImage className="h-full" image={getImage(drinksImage)} alt="Drinks!" />
                    </div>
                  </Carousel.Slide>
                </Carousel.Swiper>
              </Carousel>
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-gray-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className="bg-teal-light pt-clamp-33-40 pb-clamp-18-31">
          <div className="container flex lg:justify-center">
            <div className="lg:w-10/12">
              <div data-aos="fade-up" className="mt-clamp-9-12 lg:pl-4">
                <h3 className="text-clamp-26-32">JK Design</h3>
                <p className="mt-4">
                  201 South Main Street<br></br>
                  Suite 2, Building A<br></br>
                  Lambertville, NJ 08530
                </p>
                <p className="mt-11">908-428-4700</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default ContactPage

export const contactQuery = graphql`
  query contactQuery {
    inOfficeImage: file(relativePath: { eq: "careers/carousel/in-office.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    studioImage: file(relativePath: { eq: "careers/carousel/studio.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    roofJKImage: file(relativePath: { eq: "contact/carousel/roof-jk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lakeFarmhouseImage: file(relativePath: { eq: "contact/carousel/lake-farmhouse.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    drinksImage: file(relativePath: { eq: "contact/carousel/drinks.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
